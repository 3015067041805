import React from 'react';
import PropTypes from 'prop-types';

import chevronRight from '../icons/chevron-right-secondary.svg';

function Pagination({ pageNo, setPageNo, maxPageNo }) {
  return (
    <div className="w-full flex max-w-xs">
      <img
        src={chevronRight}
        onClick={() => setPageNo(Math.max(pageNo - 1, 1))}
        className="cursor-pointer float-left transform rotate-180"
      />
      <div className="font-semiBold text-center flex-grow">{`${pageNo} of ${maxPageNo}`}</div>
      <img
        src={chevronRight}
        onClick={() => setPageNo(Math.min(pageNo + 1, maxPageNo))}
        className="cursor-pointer float-right"
      />
    </div>
  );
}

Pagination.propTypes = {
  pageNo: PropTypes.number.isRequired,
  setPageNo: PropTypes.func.isRequired,
  maxPageNo: PropTypes.number,
};

export default Pagination;
