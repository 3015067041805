import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import plusIcon from '../icons/plus.svg';
import closeIcon from '../icons/cross-icon.svg';

function MobileDropdownFilter({ label, options, onChange, className }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const renderOptions = () =>
    options.map((item) => (
      <div
        key={item}
        className="p-2 text-sm leading-6"
        onClick={() => {
          setIsMenuOpen(false);
          onChange(item);
        }}
      >
        <div className="px-4 py-2 hover:bg-neutral-1">{item.label}</div>
      </div>
    ));

  return (
    <div>
      <div
        className={clsx(
          'px-6 py-4 border-primary-1 text-sm leading-6 flex',
          className,
        )}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="flex-grow">
          {label.split(' ')[0] === 'All' ? 'All' : label}
        </div>
        <img src={isMenuOpen ? closeIcon : plusIcon} alt="icon" />
      </div>
      {isMenuOpen ? renderOptions() : null}
    </div>
  );
}

MobileDropdownFilter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

MobileDropdownFilter.defaultProps = {
  className: 'border-t  border-b',
};

export default MobileDropdownFilter;
