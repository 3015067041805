import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import BlogFiltersDesktop from '../../components/BlogFiltersDesktop';
import BlogFiltersMobile from '../../components/BlogFiltersMobile';
import Pagination from '../../components/Pagination';
import _ from 'lodash';
import { CommonContext } from '../../context';
import { GatsbyImage } from 'gatsby-plugin-image';

export const query = graphql`
  query {
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: { featuredImageId: { ne: null } }
    ) {
      nodes {
        slug
        title
        excerpt
        date(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
    years: allWpPost {
      nodes {
        date(formatString: "YYYY")
      }
    }
    topics: allWpPost {
      group(field: categories___nodes___slug) {
        fieldValue
      }
    }
  }
`;

const months = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
];

const DEFAULT_SELECTED_MONTH = { label: 'All Months', value: 'all' };
const DEFAULT_SELECTED_YEAR = { label: 'All Years', value: 'all' };
const DEFAULT_SELECTED_TOPIC = { label: 'All Topics', value: 'all' };
const PAGE_SIZE = 5;

function BlogArchives({ data, location }) {
  const [selectedTopic, setSelectedTopic] = useState(DEFAULT_SELECTED_TOPIC);
  const [selectedMonth, setSelectedMonth] = useState(DEFAULT_SELECTED_MONTH);
  const [selectedYear, setSelectedYear] = useState(DEFAULT_SELECTED_YEAR);

  const [pageNo, setPageNo] = useState(1);
  const [maxPageNo, setMaxPageNo] = useState(1);
  useEffect(() => setPageNo(1), [selectedYear, selectedMonth, selectedTopic]);
  const topics = data.topics.group.map((item) => ({
    label: _.startCase(item.fieldValue),
    value: item.fieldValue,
  }));
  const uniqueYears = new Set(data.years.nodes.map((item) => item.date));
  const years = [];
  uniqueYears.forEach((item) =>
    years.push({
      value: item,
      label: item,
    }),
  );
  const blogData = [...data.allWpPost.nodes];

  const getPaginatedData = (blogList) => {
    const newMaxPageNo = Math.ceil(blogList.length / PAGE_SIZE);
    if (newMaxPageNo !== maxPageNo) setMaxPageNo(newMaxPageNo);
    const startIndex = (pageNo - 1) * PAGE_SIZE;
    return blogList.slice(startIndex, startIndex + PAGE_SIZE);
  };

  const getFilteredBlogs = () => {
    let targetBlogList = blogData;
    if (selectedTopic.value !== DEFAULT_SELECTED_TOPIC.value) {
      targetBlogList = targetBlogList.filter(
        (item) =>
          item.categories.nodes.find(
            (cat) => cat.slug == selectedTopic.value,
          ) !== null,
      );
    }

    if (selectedYear.value !== DEFAULT_SELECTED_YEAR.value) {
      targetBlogList = targetBlogList.filter((item) =>
        item.date.includes(selectedYear.value),
      );
    }

    if (selectedMonth.value !== DEFAULT_SELECTED_MONTH.value) {
      targetBlogList = targetBlogList.filter((item) =>
        item.date.includes(selectedMonth.value),
      );
    }

    return getPaginatedData(targetBlogList);
  };

  const renderBlogItem = (item, idx) => (
    <Link to={`/blog/${item.slug}/`} key={`blog-item-${idx}`}>
      <div className="mb-12 md:flex">
        <GatsbyImage
          className="mb-4 mr-4 md:w-96 rounded-2xl md:mb-6 overflow-clip"
          image={item.featuredImage.node.gatsbyImage}
          alt={item.featuredImage.node.altText}
        />
        <div className="max-w-lg">
          <div className="mb-2 text-sm font-regular">
            <span className="uppercase">
              {item.categories.nodes.map((cat) => cat.name).join(' | ')}
            </span>{' '}
            | {item.date}
          </div>
          <div className="mb-2 font-semiBold md:mb-4">{item.title}</div>
          <p dangerouslySetInnerHTML={{ __html: item.excerpt }}></p>
        </div>
      </div>
    </Link>
  );

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Archive" location={location}>
        <div className="flex justify-center w-full from-primary-2 bg-gradient-to-r to-primary-3">
          <div className="w-full max-w-6xl px-6">
            <div className="flex justify-center text-xl py-7 font-regular md:text-3xl">
              <div className="w-full max-w-6xl">GTAF BLOG</div>
            </div>
          </div>
        </div>
        <BlogFiltersDesktop
          years={[DEFAULT_SELECTED_YEAR, ...years]}
          months={[DEFAULT_SELECTED_MONTH, ...months]}
          topics={[DEFAULT_SELECTED_TOPIC, ...topics]}
          selectedTopic={selectedTopic}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          setSelectedTopic={setSelectedTopic}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
        />
        <BlogFiltersMobile
          years={[DEFAULT_SELECTED_YEAR, ...years]}
          months={[DEFAULT_SELECTED_MONTH, ...months]}
          topics={[DEFAULT_SELECTED_TOPIC, ...topics]}
          selectedTopic={selectedTopic}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          setSelectedTopic={setSelectedTopic}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
        />
        <div className="flex justify-center w-full">
          <div className="w-full max-w-6xl px-6">
            <div className="py-8 text-xl font-regular md:text-3xl md:py-12">
              {selectedMonth.value !== DEFAULT_SELECTED_MONTH.value
                ? selectedMonth.label
                : ''}{' '}
              {selectedYear.value !== DEFAULT_SELECTED_YEAR.value
                ? selectedYear.label
                : ''}
            </div>
            {getFilteredBlogs().map((item, idx) => renderBlogItem(item, idx))}
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-36">
          <Pagination
            pageNo={pageNo}
            setPageNo={setPageNo}
            maxPageNo={maxPageNo}
          />
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

BlogArchives.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default BlogArchives;
