import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MobileDropdownFilter from './MobileDropdownFilter';

import MenuIcon from '../icons/menu.svg';
import closeIcon from '../icons/cross-icon.svg';

function BlogFiltersMobile({
  years,
  months,
  topics,
  selectedTopic,
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedTopic,
  setSelectedYear,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const renderMobileMenu = () => (
    <div className="absolute top-0 z-20 w-screen h-screen bg-neutral">
      <div className="bg-neutral">
        <div className="p-6">
          <img
            src={closeIcon}
            className="w-6 h-6 cursor-pointer"
            onClick={() => setIsMenuOpen(false)}
          />
        </div>
        <MobileDropdownFilter
          label={selectedTopic.label}
          options={topics}
          onChange={setSelectedTopic}
        />
        <MobileDropdownFilter
          label={selectedYear.label}
          options={years}
          onChange={setSelectedYear}
        />
        <MobileDropdownFilter
          label={selectedMonth.label}
          options={months}
          onChange={setSelectedMonth}
        />
        <button
          className="w-screen h-16 text-sm bg-primary text-neutral"
          onClick={() => setIsMenuOpen(false)}
        >
          Done
        </button>
      </div>
    </div>
  );

  return (
    <div className="relative flex items-center justify-center h-20 md:hidden bg-primary-0">
      <div className="flex w-full px-6 text-sm leading-6">
        <img
          className="inline-block w-6 h-6 mr-2 cursor-pointer md:hidden"
          src={MenuIcon}
          onClick={() => setIsMenuOpen(true)}
        />
        Filter
      </div>
      {isMenuOpen ? renderMobileMenu() : null}
    </div>
  );
}

BlogFiltersMobile.propTypes = {
  years: PropTypes.array.isRequired,
  months: PropTypes.array.isRequired,
  topics: PropTypes.array.isRequired,

  selectedTopic: PropTypes.object.isRequired,
  selectedMonth: PropTypes.object.isRequired,
  selectedYear: PropTypes.object.isRequired,
  setSelectedTopic: PropTypes.func.isRequired,
  setSelectedMonth: PropTypes.func.isRequired,
  setSelectedYear: PropTypes.func.isRequired,
};

export default BlogFiltersMobile;
