import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from 'react-click-away-listener';

import chevronDown from '../icons/chevron-down.svg';

function DropdownFilter({ label, options, onChange }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
      <div className="w-32 mr-20">
        <div
          className="inline-flex text-sm leading-6 tracking-wide cursor-pointer font-regular"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {label}
          <img src={chevronDown} className="w-6 h-6" alt="arrow icon" />
        </div>
        {isMenuOpen ? (
          <div
            className={`absolute mt-2 w-36 rounded-2xl shadow-2xl bg-neutral z-10 py-2 right-0 md:right-auto text-accent`}
          >
            <div className="py-1">
              {options.map((item) => (
                <div
                  key={item}
                  className="flex items-center h-8 px-6 py-1 leading-6 tracking-wide font-regular hover:bg-neutral-1"
                  onClick={() => {
                    setIsMenuOpen(false);
                    onChange(item);
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

DropdownFilter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DropdownFilter;
